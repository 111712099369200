import { addMonths } from "date-fns";

const parseOrZero = (num) => {
  return num ? parseFloat(num) : 0;
};

const calcPurchasePrice = (msrp, discounts, rebates) => {
  return (
    parseOrZero(msrp) -
    parseOrZero(discounts) -
    parseOrZero(rebates)
  ).toFixed(2);
};

const calcSalesTaxAmount = (msrp, discounts, salesTaxPercent) => {
  let ta =
    (parseOrZero(msrp) - parseOrZero(discounts)) *
    (parseOrZero(salesTaxPercent) / 100);

  return ta.toFixed(2);
};

const calcSalesTaxPercent = (msrp, discounts, salesTaxAmount) => {
  let tp =
    (100 * parseOrZero(salesTaxAmount)) /
    (parseOrZero(msrp) - parseOrZero(discounts));

  return tp.toFixed(3);
};

const calcInterestAmount = (purchasePrice, months, monthlyPayment) => {
  return Math.abs(
    parseOrZero(purchasePrice) -
      parseOrZero(months) * parseOrZero(monthlyPayment)
  ).toFixed(2);
};

const calcMonthlyPayment = (interestRate, months, loanAmount) => {
  const R = parseOrZero(interestRate) / 1200;
  const n = parseOrZero(months);
  const pv = parseOrZero(loanAmount);
  return ((pv * R) / (1 - Math.pow(1 + R, n * -1))).toFixed(2);
};

const calcLoanAmount = (purchasePrice, fees, downPayment, salesTaxAmount) => {
  return (
    parseOrZero(purchasePrice) +
    parseOrZero(fees) +
    parseOrZero(salesTaxAmount) -
    parseOrZero(downPayment)
  ).toFixed(2);
};

const calcPaymentsTotal = (months, monthlyPayment) => {
  return (parseOrZero(months) * parseOrZero(monthlyPayment)).toFixed(2);
};

const calcMonthlyInterestAmount = (currentBalance, interestRate) => {
  const interest =
    (parseOrZero(currentBalance) * parseOrZero(interestRate)) / 100 / 12;
  const rounded = Math.round(interest * 100) / 100;
  return rounded.toFixed(2);
};

const calcMonthlyPrincipalAmount = (
  monthlyInterestAmount,
  totalPaidForMonth
) => {
  const principal =
    parseOrZero(totalPaidForMonth) - parseOrZero(monthlyInterestAmount);
  const rounded = Math.round(principal * 100) / 100;
  return rounded.toFixed(2);
};

export const getDefaults = (values = {}) => {
  // copy the values obect that was passed in
  const defaults = {
    extraPayments: {},

    ...values,
  };

  return defaults;
};

export const getValue = (state, value, values) => {
  if (value) {
    return state[value];
  } else if (values) {
    return values.reduce((acc, cur) => acc + parseOrZero(state[cur]), 0);
  } else {
    return 0;
  }
};

export const calculateAmortization = (v) => {
  /**
   * calculate:
   *  * month (date of payment)
   *  * payment amount (would be the same every month)
   *  * extra amount
   *  * total paid for month
   *  * remaining balance
   *  * amount going towards principal
   *  * amount going towards interest
   *  * LTV (loan-to-value)
   */
  const {
    months,
    monthlyPayment,
    loanAmount,
    startDate: startDateString,
    interestRate,
    purchasePrice,
    extraPayments,
  } = v;

  const newDate = new Date(startDateString);
  const startDate = new Date(
    newDate.valueOf() + newDate.getTimezoneOffset() * 60 * 1000
  );

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const payments = [...new Array(parseInt(months))]
    .map((_, monthIndex) => monthIndex)
    .reduce((pastMonthlyPayments, currentPaymentMonthIndex) => {
      const currentBalance =
        currentPaymentMonthIndex === 0
          ? loanAmount
          : pastMonthlyPayments.slice(-1)[0].remainingBalance;

      if (parseFloat(currentBalance) <= 0) {
        return pastMonthlyPayments;
      }

      const paymentDate = addMonths(startDate, currentPaymentMonthIndex);
      const month = monthNames[paymentDate.getMonth()];
      const year = paymentDate.getFullYear();

      const extraPaymentAmount = extraPayments[currentPaymentMonthIndex] || 0;
      const totalPaidForMonth = (
        parseOrZero(monthlyPayment) + parseOrZero(extraPaymentAmount)
      ).toFixed(2);

      const monthlyInterestAmount = calcMonthlyInterestAmount(
        currentBalance,
        interestRate
      );
      const monthlyPrincipalAmount = calcMonthlyPrincipalAmount(
        monthlyInterestAmount,
        totalPaidForMonth
      );
      let remainingBalance = (
        parseOrZero(currentBalance) - parseOrZero(monthlyPrincipalAmount)
      ).toFixed(2);

      if (parseFloat(remainingBalance) < 0) {
        remainingBalance = (0).toFixed(2);
      }

      const loanToValue = ((remainingBalance / purchasePrice) * 100).toFixed(2);

      const paymentDetails = {
        paymentNumber: currentPaymentMonthIndex + 1,
        month,
        year,
        paymentAmount: monthlyPayment,
        extraPaymentAmount,
        totalPaidForMonth,
        remainingBalance,
        monthlyInterestAmount,
        monthlyPrincipalAmount,
        loanToValue,
      };

      pastMonthlyPayments.push(paymentDetails);

      return pastMonthlyPayments;
    }, []);

  return payments;
};

export const calculateLoan = (v) => {
  const purchasePrice = calcPurchasePrice(v.msrp, v.discounts, v.rebates);
  v.purchasePrice = purchasePrice;

  const salesTaxAmount = calcSalesTaxAmount(
    v.msrp,
    v.discounts,
    v.salesTaxPercent
  );
  v.salesTaxAmount = salesTaxAmount.toString();

  // const salesTaxPercent = this.calcSalesTaxPercent(v.msrp, v.discounts, v.salesTaxAmount);
  // v.salesTaxPercent = keyToIgnore === 'salesTaxPercent' ? v.salesTaxPercent : salesTaxPercent.toString();

  const loanAmount = calcLoanAmount(
    v.purchasePrice,
    v.fees,
    v.downPayment,
    v.salesTaxAmount
  );
  v.loanAmount = loanAmount;

  const monthlyPayment = calcMonthlyPayment(
    v.interestRate,
    v.months,
    v.loanAmount
  );
  v.monthlyPayment = isNaN(monthlyPayment) ? null : monthlyPayment;

  const interestAmount = calcInterestAmount(
    v.purchasePrice,
    v.months,
    v.monthlyPayment
  );
  v.interestAmount = interestAmount;

  const paymentsTotal = calcPaymentsTotal(v.months, v.monthlyPayment);
  v.paymentsTotal = paymentsTotal;

  return v;
};
