import React from "react";
import FormPageTemplate from "../components/FormPageTemplate";
import {
  calculateLoan,
  calculateAmortization,
  getDefaults,
} from "../utils/loanFuncs/vehiclePurchaseFuncs";
import ResultsForm from "../components/ResultsForms";
import LoanPageStyles from "../styles/LoanPageStyles";
import SEO from "../components/SEO";

const inputs = [
  // each nested array is a fieldset
  [
    {
      name: "loanName",
      label: "Loan Name",
      required: true,
      placeholder: "",
      autocomplete: "",
      fullWidth: true,
    },
  ],
  [
    {
      name: "msrp",
      label: "MSRP",
      type: "number",
      required: true,
      placeholder: "",
      autocomplete: "",
      allowDecimal: true,
    },
    {
      name: "fees",
      label: "Fees",
      type: "number",
      placeholder: "",
      autocomplete: "",
      allowDecimal: true,
    },
  ],
  [
    {
      name: "discounts",
      label: "Discounts",
      type: "number",
      // required: true,
      placeholder: "",
      autocomplete: "",
      allowDecimal: true,
    },
    {
      name: "rebates",
      label: "Rebates",
      type: "number",
      // required: true,
      placeholder: "",
      autocomplete: "",
      allowDecimal: true,
    },
  ],
  [
    {
      name: "downPayment",
      label: "Down Payment",
      type: "number",
      // required: true,
      placeholder: "",
      autocomplete: "",
      allowDecimal: true,
    },
    {
      name: "months",
      label: "Months",
      type: "number",
      required: true,
      placeholder: "",
      autocomplete: "",
    },
  ],
  [
    {
      name: "salesTaxPercent",
      label: "Sales Tax",
      type: "number",
      required: true,
      placeholder: "",
      autocomplete: "",
      allowDecimal: true,
    },
    {
      name: "interestRate",
      label: "Interest Rate",
      type: "number",
      required: true,
      placeholder: "",
      autocomplete: "",
      allowDecimal: true,
    },
  ],
  [
    {
      name: "startDate",
      label: "Start Date",
      type: "date",
      required: true,
      placeholder: "",
      autocomplete: "",
    },
  ],
];

const computedFields = [
  { label: "MSRP", field: "msrp" },
  { label: "Purchase price", field: "purchasePrice" },
  { label: "Sales tax", field: "salesTaxAmount" },
  { label: "Payments Total", field: "paymentsTotal" },
];

const amortizationColumnDefs = [
  {
    label: "#",
    field: "paymentNumber",
    style: {
      minWidth: 50,
    },
    headerCellStyle: {},
    dataCellStyle: {
      padding: "0.5rem 0",
      border: "1px solid var(--blue)",
    },
  },
  {
    label: "Month",
    field: "month",
  },
  {
    label: "Year",
    field: "year",
  },
  {
    label: "Interest",
    field: "monthlyInterestAmount",
    isDollarAmount: true,
  },
  {
    label: "Principal",
    field: "monthlyPrincipalAmount",
    isDollarAmount: true,
  },
  {
    label: "Total",
    field: "totalPaidForMonth",
    isDollarAmount: true,
  },
  {
    label: "Remaining",
    field: "remainingBalance",
    isDollarAmount: true,
  },
  {
    label: "LTV",
    field: "loanToValue",
    isPercentage: true,
  },
];

const formDefaults = {
  loanName: '',
  msrp: 48990,
  fees: 1500,
  discounts: 0,
  rebates: 0,
  downPayment: 5000,
  months: 72,
  salesTaxPercent: 6.9,
  interestRate: 2.49,
  startDate: "2021-07-28",
};

const formOptions = {
  requiredFields: inputs
    .flat()
    .filter((i) => i.required)
    .map((i) => i.name),
  calculateLoan,
  calculateAmortization,
  getDefaults,
};

export default function ({ data }) {
  return (
    <>
      <SEO title={"Vehicle Purchase"} />
      <LoanPageStyles>
        <FormPageTemplate
          formKey="vehicle-purchase"
          formDefaults={formDefaults}
          formOptions={formOptions}
          style={{ marginBottom: 50 }}
          inputs={inputs}
          resultsFormProps={{
            computedFields,
            amortizationColumnDefs,
          }}
          resultsForm={ResultsForm}
        />
      </LoanPageStyles>
    </>
  );
}
